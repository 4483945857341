import React from 'react'
import omit from 'lodash/omit'
import classNames from 'classnames'

import './index.less'

const Icon = React.forwardRef<HTMLElement, IconProps>((props, ref) => {
  const {
    name,
    options,
    className,
    ...restProps
  } = props

  const iconClassNames = options && options.map(option => 'icon-' + option)

  const filteredRestProps = omit(restProps, [
    // these props are set by react-slick and have to be removed again
    'currentSlide',
    'slideCount',
  ])

  return (
    <span {...filteredRestProps} className={classNames('icon', 'icon-' + name, iconClassNames, className)} ref={ref} />
  )
})

interface IconProps extends React.HTMLAttributes<HTMLSpanElement> {
  name: string
  options?: string[]
  className?: string
  [restProps: string]: unknown
}

Icon.displayName = 'Icon'

export default Icon
