import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import CountryLinks from 'components/Footer/CountryLinks'
import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'
import { RootState } from 'store/reducer'

import './index.less'
import BasicFooter from './basicFooter'

const NavigationLinks: React.FC = () => {
  return (
    <React.Fragment>
      <div className="centered-column-list__column">
        <h4 className="main-footer__headline"><FormattedMessage id="footer.eventinc" /></h4>

        <a className="link link--no-active-color link--no-decoration" href="https://about.eventinc.de/about-us/">
          <FormattedMessage id="footer.about_us" />
        </a>

        <a className="link link--no-active-color link--no-decoration" href="https://about.eventinc.de/career/">
          <FormattedMessage id="footer.career" />
        </a>

        <a className="link link--no-active-color link--no-decoration" href="https://about.eventinc.de/press/">
          <FormattedMessage id="footer.press" />
        </a>

        <a className="link link--no-active-color link--no-decoration" href="https://about.eventinc.de/contact/">
          <FormattedMessage id="footer.contact" />
        </a>
      </div>

      <div className="centered-column-list__column">
        <h4 className="main-footer__headline"><FormattedMessage id="footer.service" /></h4>

        <a className="link link--no-active-color link--no-decoration" href="https://blog.eventinc.de/">
          <FormattedMessage id="footer.magazine" />
        </a>

        <a className="link link--no-active-color link--no-decoration" href="https://blog.eventinc.de/blog-sitemap">
          <FormattedMessage id="footer.blog_sitemap" />
        </a>
      </div>

      <div className="centered-column-list__column">
        <h4 className="main-footer__headline"><FormattedMessage id="footer.suppliers" /></h4>

        <a
          className="link link--no-active-color link--no-decoration"
          href="https://join.eventinc.de/"
        >
          <FormattedMessage id="footer.advertise" />
        </a>
      </div>
    </React.Fragment>
  )
}

const Footer: React.FC<FooterProps> = ({
  renderNavigationLinks = true,
}) => {
  const {
    isCurrentCountryDach,
    isPreferredPartner,
  } = useSelector((state: RootState) => ({
    isCurrentCountryDach: state.headerData.isCurrentCountryDach,
    isPreferredPartner: state.currentUser.isPreferredPartner,
  }), shallowEqual)

  return (
    <div className="footer">
      <div className="main-footer">
        {!isPreferredPartner && renderNavigationLinks && (
          <div className="main-footer__links-section centered-column-list">
            {isCurrentCountryDach && <NavigationLinks />}

            <div className="centered-column-list__column">
              <CountryLinks />
            </div>
          </div>
        )}

        <div className="main-footer__social-media-icon-container">
          <div className="footer-social-media-list">
            <a target="_blank" href="https://twitter.com/Event_Inc">
              <Icon name="twitter-square" />
            </a>
            <a target="_blank" href="https://www.facebook.com/eventinc.de">
              <Icon name="facebook" />
            </a>
            <a target="_blank" href="https://www.pinterest.com/eventinc/">
              <Icon name="pinterest" />
            </a>
            <a target="_blank" href="https://www.youtube.com/channel/UCjYloWVKdx2r84PA6lhhiPQ">
              <Icon name="youtube-square" />
            </a>
          </div>
        </div>

        <BasicFooter />

      </div>
    </div>
  )
}

interface FooterProps {
  renderNavigationLinks?: boolean,
  basicFooter?: boolean,
}

export default Footer
